.account-modal-body{
     padding: 1.5rem;
}
.cancel-popup{
     height: 2.5rem;
     margin-bottom: 1rem;
}
.search-text-field{
     width: 100%;
}
.account-switch-title{
     font-size: 1.5rem;
     font-weight: 400;
}
.account-list{
     margin-top:1.2rem;
}
.account-disp{
     margin: 0.75rem 0;
}
.progress-spin{
     display: flex;
     justify-content: center;
     margin: 3rem 0;
}
.trimble-logo{
     width: 113px;
     margin-bottom: 1rem;
}
.disable-section{
     opacity: 0.6;
     pointer-events: none;
}
.action-button{
    float: right;
    width: 100%;
    margin-top: 1.3rem !important;
    font-weight: 700 !important;
    font-size: 0.875rem !important;
    line-height: normal !important;
}
.disable-button{
     @extend .action-button;
     border: none !important;
     color: inherit !important;
     text-transform: none !important;

}