.account-holder{
     border-bottom: 0.12rem solid #eaeaef;
     margin: 1.5rem 0;
}

.list-view-acc{
     font-weight: 600;
     display: flex;
     align-items: flex-start;
}
.acc-name{
     word-break: break-word;
     margin: 0 0.5rem 1rem 0;
     font-weight: 700;
}
.acc-text{
     margin: 1.5rem 0;
}
a{
     color: #217cbb;
     text-decoration: none;
     :hover{
          text-decoration: underline;
     }
}

.custom-badge {
     padding: 0.1rem 0.3rem;
     background-color: #0063a3;
     color: white;
     border-radius: 0.25rem;
     font-size: 0.7rem;
     font-weight: 700;
     width: fit-content !important;
}
.custom-badge-grey{
     @extend .custom-badge;
     background-color: #6A6E79;
}
.custom-badge-green{
     @extend .custom-badge;
     background-color: #006638;
}



