.card.accounts {
     min-height: 328px;

     .identification-holder {
          padding-bottom: 16px;
     }

     .email-holder {
          margin-bottom: 16px;
          overflow-wrap: break-word;
     }

     .mfa-methods {
          margin-top: 24px;
          margin-bottom: 16px;
     }

     .mfa-holder {
          display: flex;
          flex-wrap: wrap;
          border-top: 0.12rem solid #eaeaef;
          margin-bottom: 1.5rem;

          h4 {
               align-items: center;
               .MuiSwitch-root {
                    margin: auto;
               }
          }
          .MuiSwitch-colorPrimary.Mui-checked {
               color: #005f9e;
          }
          .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
               background-color: #005f9e;
          }

          .MuiSwitch-colorPrimary.Mui-checked:hover {
               background-color: rgba(0, 95, 158, 0.04);
          }
     }
     .link-holder {
          display: flex;
          flex-direction: column;
          .link {
               margin-bottom: 16px;
               font-weight: 600;
          }
     }

     .notes {
          padding-bottom: 8px;
     }
     .list-view-switch{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
     }
     .list-view-section{
          margin: 1rem 0;
          width: 100%;        
     }
     .list-view{
          display: flex;
          justify-content: space-between;
          margin-top: 1.5rem;
     }
     .each-list-view{
          display: flex;
          max-width: 80%;
          justify-content: space-between;
     }
     .list-view-icon{
          margin-right: 0.7rem;
          color: #7D808D !important;
     }
     .list-view-icon-enabled{
          @extend .list-view-icon;
          color: #349C44 !important;
     }
     .list-view-action{
          color: #005f9e;
          cursor: pointer;
          font-weight: 600;
     }
     .list-view-title{
          font-weight: 600;
          margin-bottom: 0.3rem;
          word-break: break-word;
     }
     .passkey-time{
          color: #6A6E79;
          font-weight: 400;
          line-height: 1.31rem;
     }
     .passkey-icon-static{
          margin-right: 0.7rem;
     }
     .passkey-icon-disabled{
          margin-right: 0.7rem;
          opacity: 0.6 !important;
     }
     .passkey-icon{
          margin-right: 0.7rem;
          color: #7D808D !important;
          padding: 2px;
     }
     .passkey-icon:hover{
          margin-right: 0.7rem;
          color: black !important;
          background-color: #E0E1E9;
          border-radius: 4px;
     }
     .mfa-menu-icon{
          color: #7D808D !important;
          cursor: pointer;
          cursor: pointer;
     }
     .custom-badge {
          padding: 0.1rem 0.3rem;
          background-color: #0063a3;
          color: white;
          border-radius: 0.25rem;
          font-size: 0.7rem;
          margin-left: 0.5rem;
          font-weight: 700;
     }
     .passkey-action{
          display: flex;
          justify-content: space-between;
          cursor: pointer;
     }
     .passkey-box{
          border-radius: 0.25rem;
          border: 0.08rem solid #CBCDD6;
          padding: 0.7rem;
     }
     .passkey-holder{
          border-top: 0.12rem solid #eaeaef;
          margin-bottom: 1.5rem;
     }
   
     .link-button{
          text-decoration: none;
          color: #005f9e;
          cursor: pointer;
          font-weight: 600;

     }
     .MuiAlert-outlinedInfo {
          border-left-width: 6px;
          border-color: #005f9e;
     }

     .MuiAlert-outlinedInfo .MuiAlert-icon {
          color: #005f9e;
     }

     .help-icon {
          width: 24px;
          height: 24px;

          .MuiSvgIcon-root {
               width: 100%;
               height: 100%;
          }
     }
    
}
/*overriding mui popover style*/

.mfa-action-pop{
  margin-top: 1.2rem !important;
  margin-left: -3rem !important;
}

.mfa-action-pop-item{
     padding: 0.8rem 1.2rem !important;
     cursor: pointer !important;
}
.mfa-action-pop-item:hover{
     padding: 0.8rem 1.2rem !important;
     cursor: pointer !important;
     background-color: #CFE1EE !important;
}
.prompt-info{
     display: flex;
     background-color: #DCEDF9;
     padding: 0.5rem;
     color: #0063A3;
     margin: 0.7rem 0;
}
.prompt-text{
     margin-left: 0.5rem;
}
.totp-link{
     margin-top:1rem;
     cursor: pointer;
     font-weight: 700;
}
.totp-link:hover{
     margin-top:1rem;
     cursor: pointer;
     font-weight: 700;
     text-decoration: underline;
}